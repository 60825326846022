import React from "react"
import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import MainCarousel from "../components/MainCarousel"
import CategoryContainer from "../components/CategoryContainer"
import AboutUs from "../components/AboutUs"
import OurBrands from "../components/OurBrands"
import DealsContainer from "../components/DealsContainer"

const IndexPage = () => {
  const {t}= useTranslation()
  return (
  <Layout>
    <SEO title={t("home")} />
    <MainCarousel/>
    <DealsContainer />
    <CategoryContainer />
    <OurBrands />
    <AboutUs/>
  </Layout>)
}

export default IndexPage
