import React from "react"
import category from "../images/category.png"

const Category= props =>(
    <div className="categorySquare">
        <img src={category} className="categoryImage" />
        <h5>Chalecos de seguridad</h5>
    </div>
)

export default Category