import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import brand1 from "../images/brand1.png"
import brand2 from "../images/brand2.jpeg"

const OurBrands=()=>(
    <div className="ourBrands">
         <h2>Official representation</h2>
        <Row className="justify-content-center" style={{textAlign:"center"}}>
            <Col lg={3} md={4} sm={6} xs={6}>
                <img src={brand1} className="brandLogo" />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
                <img src={brand2} className="brandLogo" />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
                <img src={brand1} className="brandLogo" />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
                <img src={brand2} className="brandLogo" />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
                <img src={brand1} className="brandLogo" />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
                <img src={brand2} className="brandLogo" />
            </Col>
        </Row>
    </div>
)

export default OurBrands