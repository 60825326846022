import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Category from "./Category"
const CategoryContainer= props =>(
    <div class="categoryContainer">
        <h2>Categorías</h2>
        <Row className="justify-content-center">
            <Col lg={3} md={4} sm={6} xs={6}>
                <Category />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
                <Category />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
                <Category />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
                <Category />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
                <Category />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
                <Category />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
                <Category />
            </Col>
            <Col lg={3} md={4} sm={6} xs={6}>
                <Category />
            </Col>
        </Row>
    </div>
)

export default CategoryContainer