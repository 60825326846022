import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import product1 from "../images/shoes.png"
import product2 from "../images/mask.jpeg"

const DealsContainer=()=>(
    <div className="dealsContainer">
         <h1>Ejemplo del lema de la empresa</h1>
         <Row className="justify-content-center">
             <Col lg={2} md={3} sm={4} xs={6}>
                <div className="dealItem">
                    <div className="dealImageContainer">
                        <img src={product1} className="dealImage" />
                    </div>
                    <div className="dealName">Producto 1</div>
                    <div className="price"><span className="newPrice">$10</span> <span className="oldPrice">$20</span></div>
                </div>
             </Col>
             <Col lg={2} md={3} sm={4} xs={6}>
                <div className="dealItem">
                    <div className="dealImageContainer">
                        <img src={product2} className="dealImage" />
                    </div>
                    <div className="dealName">Producto 2</div>
                    <div className="price"><span className="newPrice">$10</span> <span className="oldPrice">$20</span></div>
                </div>
             </Col>
             <Col lg={2} md={3} sm={4} xs={6}>
                <div className="dealItem">
                    <div className="dealImageContainer">
                        <img src={product1} className="dealImage" />
                    </div>
                    <div className="dealName">Producto 1</div>
                    <div className="price"><span className="newPrice">$10</span> <span className="oldPrice">$20</span></div>
                </div>
             </Col>
             <Col lg={2} md={3} sm={4} xs={6}>
                <div className="dealItem">
                    <div className="dealImageContainer">
                        <img src={product2} className="dealImage" />
                    </div>
                    <div className="dealName">Producto 2</div>
                    <div className="price"><span className="newPrice">$10</span> <span className="oldPrice">$20</span></div>
                </div>
             </Col>
             <Col lg={2} md={3} sm={4} xs={6}>
                <div className="dealItem">
                    <div className="dealImageContainer">
                        <img src={product1} className="dealImage" />
                    </div>
                    <div className="dealName">Producto 1</div>
                    <div className="price"><span className="newPrice">$10</span> <span className="oldPrice">$20</span></div>
                </div>
             </Col>
             <Col lg={2} md={3} sm={4} xs={6}>
                <div className="dealItem">
                    <div className="dealImageContainer">
                        <img src={product2} className="dealImage" />
                    </div>
                    <div className="dealName">Producto 2</div>
                    <div className="price"><span className="newPrice">$10</span> <span className="oldPrice">$20</span></div>
                </div>
             </Col>
             <Col lg={2} md={3} sm={4} xs={6}>
                <div className="dealItem">
                    <div className="dealImageContainer">
                        <img src={product2} className="dealImage" />
                    </div>
                    <div className="dealName">Producto 2</div>
                    <div className="price"><span className="newPrice">$10</span> <span className="oldPrice">$20</span></div>
                </div>
             </Col>
             <Col lg={2} md={3} sm={4} xs={6}>
                <div className="dealItem">
                    <div className="dealImageContainer">
                        <img src={product2} className="dealImage" />
                    </div>
                    <div className="dealName">Producto 2</div>
                    <div className="price"><span className="newPrice">$10</span> <span className="oldPrice">$20</span></div>
                </div>
             </Col>
             <Col lg={2} md={3} sm={4} xs={6}>
                <Button className="moreButton">Ver más ofertas</Button>
             </Col>
             
         </Row>
    </div>
)
export default DealsContainer