import React from "react"
import Carousel from "react-bootstrap/Carousel"
import firstPicture from "../images/1.jpg"
import secondPicture from "../images/2.jpg"
import thirdPicture from "../images/3.jpg"



const MainCarousel=(props)=>{
    return (
        <div  className="carouselContainer">
    <Carousel className="landingCarousel">
        <Carousel.Item>
            <img
            className="carrouselImage"
            src={firstPicture}
            alt="First slide"
            />
            <Carousel.Caption>
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <img
            className="carrouselImage"
            src={secondPicture}
            alt="Second slide"
            />

            <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <img
            className="carrouselImage"
            src={thirdPicture}
            alt="Third slide"
            />

            <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
            </Carousel.Caption>
        </Carousel.Item>
    </Carousel>
    </div>
    )
}

export default MainCarousel